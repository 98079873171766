<template>
  <div>
    <v-container class="face-enroll-container">
      <v-row>
        <v-col
          :cols="$vuetify.breakpoint.smAndUp ? 9 : 12"
          class="d-flex align-center"
        >
          <img
            src="@/assets/icons/face-enroll-new.svg"
            height="22"
            width="22"
            alt="age-icon"
          />
          <div class="main-heading">
            <span v-if="totalEnrolledFaced == null || totalEnrolledFaced == 0"
              >Face Enroll</span
            >
            <span v-else>Enrolled Faces ({{ totalEnrolledFaced }})</span>
            <v-icon class="info-icon mx-1" v-if="!alert" @click="alert = true"
              >mdi-information-outline</v-icon
            >
          </div>
        </v-col>
        <v-col
          v-if="totalEnrolledFaced > 0"
          :cols="$vuetify.breakpoint.smAndUp ? 3 : 12"
          class="d-flex justify-end"
        >
          <v-btn
            @click="faceEnrollDialog = true"
            depressed
            class="add-new text-capitalize"
          >
            <img
              src="@/assets/icons/plus-icon.svg"
              height="12"
              width="12"
              alt="plus-icon"
            />
            <span class="px-1">New Face Enroll</span></v-btn
          >
        </v-col>
      </v-row>
      <v-row class="secnod-row">
        <v-col cols="12">
          <v-alert
            v-model="alert"
            close-text="Close Alert"
            class="face-enroll-alert"
          >
            <template v-slot:append>
              <img
                @click="alert = false"
                src="@/assets/icons/close-icon.svg"
                height="22"
                width="22"
                class="cursor-pointer"
                alt="close-icon"
              />
            </template>
            Enroll Face is your essential tool for setting up
            <span @click="goToFaceSearch()" class="selfie-search-text"
              >'Face Search'</span
            >. It allows you to add and organize face images into a database,
            which is then used exclusively for the Face Pool Match service. This
            streamlined process ensures that when you need to match a face image
            against multiple images, your database is ready and optimised for
            quick and accurate matching.
          </v-alert>
        </v-col>
        <v-col
          v-if="totalEnrolledFaced == 0"
          cols="12"
          class="d-flex justify-center"
        >
          <div class="no-record-found">
            <img
              src="@/assets/icons/not-found-icon.svg"
              height="30"
              width="30"
              alt="not-found-icon"
            />
            <div class="no-face-enroll-text">
              No Face Enrolled! Please enroll the face.
            </div>
            <v-btn
              @click="faceEnrollDialog = true"
              depressed
              class="add-new text-capitalize"
            >
              <img
                src="@/assets/icons/plus-icon.svg"
                height="12"
                width="12"
                alt="plus-icon"
              />
              <span class="px-1">New Face Enroll</span></v-btn
            >
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <transaction-media
            @total-enrolled-faces="getTotalEnrolledFaces"
          ></transaction-media>
        </v-col>
      </v-row>
      <Camera
        v-if="startCamera"
        @attached-image="getImage"
        @close-camera-dialog="closeCamera"
      />
      <v-dialog
        v-model="faceEnrollDialog"
        persistent
        class="face-enroll-dialog"
        :width="imageSrc ? 392 : 522"
      >
        <v-card
          :disabled="isProcessing"
          class="face-enroll-card"
          :class="$vuetify.breakpoint.xsOnly ? 'face-enroll-card-mobile' : ''"
        >
          <template slot="progress">
            <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-card-title class="card-title">
            <v-row>
              <v-col cols="10">
                {{
                  filesCollection.length
                    ? "Attached files Preview"
                    : imageSrc
                    ? "Preview"
                    : "New Face Enroll"
                }}
              </v-col>
              <v-col cols="2" class="d-flex justify-end align-center">
                <img
                  @click="closeFaceEnrollDialog()"
                  src="@/assets/icons/close-icon.svg"
                  height="22"
                  width="22"
                  alt="close-icon"
                  class="cursor-pointer"
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text
            v-if="!imageSrc && getFilesCollection.length == 0"
            class="card-body"
          >
            <div class="selfie-upload">
              <div class="option">
                <div class="option-child" @click="takeSelfie()">
                  <img
                    src="@/assets/icons/camera-icon.svg"
                    height="20"
                    width="20"
                    alt="camera-icon"
                  />
                  <div class="option-text">Take Selfie</div>
                </div>
                <v-divider class="vertical-divider" vertical></v-divider>
                <div class="option-child" @click="browseClick()">
                  <img
                    src="@/assets/icons/file-upload.svg"
                    height="20"
                    width="20"
                    alt="file-upload-icon"
                  />
                  <div class="option-text">Upload Selfie</div>
                </div>
              </div>
              <div class="file-limit-text">Maximum size 3 MB</div>
            </div>
          </v-card-text>
          <v-card-text v-else-if="imageSrc" class="card-body">
            <img class="preview-img" id="image-preview" :src="imageSrc" alt=""
          /></v-card-text>
          <v-card-text
            v-else-if="getFilesCollection.length"
            class="files-list-body"
          >
            <v-list class="">
              <v-list-item
                v-for="(file, index) in getFilesCollection"
                :key="index"
                class="file-item"
                :class="file.size > fileSizeLimit ? 'file-item-error' : ''"
              >
                <v-list-item-content>
                  <div>{{ file.name }}</div>
                  <div class="file-detail">
                    <div>type: {{ file.type | fileType }}</div>
                    <div>size: {{ file.size | fileSize }}</div>
                  </div>
                </v-list-item-content>
                <v-list-item-icon>
                  <img
                    @click="removeFile(index)"
                    src="@/assets/icons/close-icon.svg"
                    height="22"
                    width="22"
                    alt="close-icon"
                    class="cursor-pointer"
                  />
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-card-text>
          <div class="pt-1" v-if="filesCollection.length">
            <small>Images Uploaded ({{ filesCollection.length }})</small>
          </div>
          <v-card-actions v-if="filesCollection.length" class="card-footer">
            <v-btn
              v-if="filesCollection.length != 10"
              @click="browseClick()"
              depressed
              class="view-result text-capitalize"
            >
              Choose images</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              :loading="isProcessing"
              :disabled="!isAllFilesCollectionSizeValid"
              @click="uploadMultipleImages()"
              depressed
              class="save-btn text-capitalize"
            >
              Save Images</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-file-input
        v-model="filesToUpload"
        id="upload-file"
        class="d-none"
        multiple
        accept="image/jpeg,image/JPEG,image/jpg,image/JPG,image/PNG,image/png"
        @change="onFileChanged()"
      ></v-file-input>
    </v-container>
  </div>
</template>

<script>
import ApiService from "../../services/Api";
import Camera from "../common/Camera.vue";
import TransactionMedia from "../common/TransactionMedia.vue";
import EventBus from "../../js/eventBus";
import {
  showErrorMessage,
  showSimpleErrorMessage,
} from "../../utils/showError";
import { API_URL } from "@/utils/env.js";
export default {
  name: "FaceEnroll",
  components: { TransactionMedia, Camera },

  data() {
    return {
      alert: true,
      startSelfieCamera: false,
      isProcessing: false,
      showResult: false,
      baseUrl: API_URL,
      totalEnrolledFaced: null,
      faceEnrollDialog: false,
      startCamera: false,
      imageSrc: null,
      fileSizeLimit: 3 * 1024 * 1024,
      filesCollection: [],
      filesToUpload: [],
    };
  },
  filters: {
    /**
     * Returns the file type based on the provided value.
     * @param {string} value - The file type value.
     * @returns {string} - The file type in uppercase.
     */
    fileType(value) {
      if (!value) return "";
      value = value.toString();
      return value.split("/").pop().toUpperCase();
    },

    /**
     * Returns the file size formatted in kilobytes (KB) or megabytes (MB).
     * @param {number} value - The file size value in bytes.
     * @returns {string} - The formatted file size string.
     */
    fileSize(value) {
      const fileSizeInKB = value / 1024;
      const fileSizeInMB = fileSizeInKB / 1024;
      if (!value) return "";
      value = value.toString();
      if (fileSizeInMB >= 1) {
        return `${Math.round(fileSizeInMB)} Mb`;
      }
      return `${Math.round(fileSizeInKB)} Kb`;
    },
  },
  computed: {
    /**
     * Returns the files collection.
     * @returns {Array} - The array containing files.
     */
    getFilesCollection() {
      return this.filesCollection;
    },

    /**
     * Checks if all files in the collection have valid sizes.
     * @returns {boolean} - Whether all file sizes are valid or not.
     */
    isAllFilesCollectionSizeValid() {
      let isValidFilesize = this.filesCollection.every(
        (file) => file.size < this.fileSizeLimit
      );
      if (!isValidFilesize) {
        showSimpleErrorMessage("each file size should not exceed 3 mb.");
      }
      return isValidFilesize;
    },
  },
  methods: {
    /**
     * Sends the captured selfie image for face enrollment.
     * Retrieves the client secret, then sends the image data to the API endpoint for enrollment.
     * Updates the UI upon success and reloads the page.
     * Displays an error message if there's an error.
     */
    sendForComparing() {
      this.isProcessing = true;

      ApiService.GetRequest("/merchant/client")
        .then((res) => {
          const formData = new FormData();
          formData.append("file_list[0]", this.selfieCameraBlob);
          ApiService.PostRequest(
            "/transaction/enroll-face",
            formData,
            "iframe",
            res.data.result.data.client_secret
          )
            .then((res) => {
              res;
              this.isProcessing = false;
              this.showResult = true;
              this.closeFaceEnrollDialog();
              this.startSelfieCamera = false;
              EventBus.$emit("getEnrollFaces");
              window.location.reload();
            })
            .catch((error) => {
              this.isProcessing = false;
              showErrorMessage(error);
              if (error.response.status === 400) {
                this.closeFaceEnrollDialog();
                this.startSelfieCamera = false;
                EventBus.$emit("getEnrollFaces");
              }
            });
        })
        .catch((error) => {
          this.isProcessing = false;
          showErrorMessage(error);
        });
    },

    /**
     * Uploads multiple images for face enrollment.
     * Retrieves the client secret, then sends the image data for each file in the collection to the API endpoint for enrollment.
     * Updates the UI upon success and reloads the page.
     * Displays an error message if there's an error and logs out the user if the response status is 401.
     */
    uploadMultipleImages() {
      this.isProcessing = true;

      ApiService.GetRequest("/merchant/client")
        .then((res) => {
          const formData = new FormData();
          this.filesCollection.forEach((blob, index) => {
            formData.append(`file_list[${index}]`, blob);
          });
          ApiService.PostRequest(
            "/transaction/enroll-face",
            formData,
            "iframe",
            res.data.result.data.client_secret
          )
            .then((res) => {
              res;
              this.isProcessing = false;
              this.showResult = true;
              this.closeFaceEnrollDialog();
              this.startSelfieCamera = false;
              EventBus.$emit("getEnrollFaces");
              window.location.reload();
            })
            .catch((error) => {
              showErrorMessage(error);
              this.isProcessing = false;
              if (error.response.status === 400) {
                this.closeFaceEnrollDialog();
                this.startSelfieCamera = false;
                EventBus.$emit("getEnrollFaces");
              }
              if (error.response.status === 401) {
                this.$store.dispatch("logout");
                this.$store.dispatch("SET_LOGOUT");
                this.$router.push("/");
              }
            });
        })
        .catch((error) => {
          this.isProcessing = false;
          showErrorMessage(error);
        });
    },

    /**
     * Redirects to the face search page.
     */
    goToFaceSearch() {
      this.$router.push({ path: "/face-search" });
    },

    /**
     * Sets the total number of enrolled faces.
     * @param {number} totalCount - The total count of enrolled faces.
     */
    getTotalEnrolledFaces(totalCount) {
      this.totalEnrolledFaced = totalCount;
    },

    /**
     * Activates the camera to take a selfie.
     */
    takeSelfie() {
      this.startCamera = true;
    },

    /**
     * Sets the captured image data and initiates the comparison process.
     * @param {object} data - The image data containing base64Image and file properties.
     */
    getImage(data) {
      this.imageSrc = data.base64Image;
      this.selfieCameraBlob = data.file;
      this.sendForComparing();
    },

    /**
     * Deactivates the camera.
     */
    closeCamera() {
      this.startCamera = false;
    },

    /**
     * Closes the face enrollment dialog and clears related data.
     */
    closeFaceEnrollDialog() {
      this.faceEnrollDialog = false;
      this.imageSrc = null;
      this.filesCollection = [];
      this.filesToUpload = [];
    },

    /**
     * @Description
     * 1. this method is used to initialize the browse click
     * @param none
     * @return none
     **/
    browseClick() {
      document.querySelector("#upload-file").click();
    },

    /**
     * @Description
     * This action is used to assign a file to imageSrc / videoSrc variable
     *  **/
    onFileChanged() {
      if (this.filesToUpload.length) {
        // Calculate the available space in the first array
        const availableSpace = 10 - this.filesCollection.length;
        // Use slice to take only the necessary elements from the second array
        const elementsToMerge = this.filesToUpload.slice(0, availableSpace);
        this.filesCollection = this.filesCollection.length
          ? [...this.filesCollection, ...elementsToMerge]
          : elementsToMerge;
      }
    },

    /**
     * Removes a file from the files to upload and files collection arrays at the specified index.
     * @param {number} index - The index of the file to remove.
     */
    removeFile(index) {
      this.filesToUpload.splice(index, 1);
      this.filesCollection.splice(index, 1);
    },
  },
};
</script>

<style scoped>
@media (max-width: 398px) {
  .sec-text {
    padding-left: 30%;
  }

  .v-btn {
    max-width: 100%;
  }

  .btn-txt {
    font-size: 10px !important;
  }
}

@media (max-width: 335px) {
  .sec-text {
    padding-left: 25%;
  }

  .btn-txt {
    font-size: 8px !important;
  }
}
.container {
  margin-top: 2rem;
}
.main-heading {
  display: flex;
  align-content: center;
  color: #292b30;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  padding-left: 10px;
}

.face-enroll-alert {
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  background: #fafafa;
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.face-enroll-alert:deep(.v-alert__wrapper) {
  align-items: start;
}
.face-enroll-alert:deep(.v-alert__content) {
  text-align: justify;
  padding-right: 20px;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.file-limit-text {
  color: #909295;
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.vertical-divider {
  width: 5px;
  height: 20px;
  min-height: 20px;
  color: #909295;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  align-self: end;
}
.option {
  display: flex;
  justify-content: center;
  align-items: end;
  column-gap: 5px;
}
.option-child {
  display: flex;
  justify-content: center;
  align-items: end;
  column-gap: 5px;
  cursor: pointer;
}
.option-text {
  color: #213cc8;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.substr-text {
  color: #213cc8;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 0 2px;
}
.preview-img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 16/9;
  border: 1px;
  border-radius: 6px;
}
.result-btns {
  column-gap: 16px;
}
.face-enroll-card .view-result {
  display: flex;
  column-gap: 4px;
  color: #292b30;
  height: 32px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  background: #fff;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.face-enroll-container .add-new {
  display: flex;
  height: 32px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #213cc8 !important;
  color: #fff;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.secnod-row {
  row-gap: 70px;
}
.no-record-found {
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 10px;
}
.no-face-enroll-text {
  width: 153px;
  color: #292b30;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.face-enroll-dialog {
  height: 465;
}
.face-enroll-card {
  padding: 0 20px 20px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 1.4px 13px 0px rgba(0, 0, 0, 0.03),
    0px 7px 80px 0px rgba(0, 0, 0, 0.05) !important;
}
.face-enroll-card-mobile {
  padding: 0 10px 20px;
}
.face-enroll-card:deep(.v-card__title) {
  padding: 16px 0 10px;
}
.face-enroll-card:deep(.v-card__text) {
  padding: 0 0px;
}
.face-enroll-card:deep(.v-card__actions) {
  padding: 20px 0 0;
}
.face-enroll-card .save-btn {
  display: flex;
  height: 32px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #213cc8 !important;
  color: #fff;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.card-title {
  display: flex;
  color: #292b30;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
.card-body {
  position: relative;
  height: 200px;
  display: flex;
  justify-content: center;
  border-radius: 6px;
  border: 1px dashed #e3e3e3;
  background: #f7f7f7;
  padding: 0;
}
.card-footer {
  /* display: flex; */
  /* justify-content: center; */
  color: #909295;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.files-list-body {
  border-radius: 6px;
  border: 1px dashed #e3e3e3;
  background: #f7f7f7;
}
.files-list-body:deep(.v-list-item__icon) {
  margin-right: 0;
}
.files-list-body:deep(.v-sheet.v-list) {
  max-height: 250px;
  overflow-y: auto;
  border-radius: 9px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
.files-list-body:deep(.v-list-item__content) {
  row-gap: 5px;
}
.selfie-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}
.selfie-search-text {
  color: #213cc8;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
}
.file-detail {
  display: flex;
  column-gap: 20%;
  row-gap: 5px;
}
.file-item {
  border-radius: 6px;
  border: 1px #e3e3e3;
  background: #f7f7f7;
}
.file-item-error {
  background: #e53935;
}
.file-item-error:deep(.v-list-item__content) {
  color: white;
}
.main-heading:deep(.info-icon) {
  font-size: 16px;
}
</style>
