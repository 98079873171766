<template>
  <div>
    <v-row v-if="!isLoading" class="images-container">
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="4"
        lg="3"
        xl="2"
        v-for="(media, i) in allMedia"
        :key="i"
      >
        <v-card class="image-card-container" @click="openImageDialog(media, i)">
          <v-img :src="media.thumbnail_url" height="200">
            <template v-slot:placeholder>
              <div class="d-flex align-center justify-center fill-height">
                <v-progress-circular
                  color="grey-lighten-4"
                  indeterminate
                ></v-progress-circular>
              </div>
            </template>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else class="images-container">
      <v-col
        v-for="i in 16"
        :key="i"
        cols="12"
        xs="12"
        sm="6"
        md="4"
        lg="3"
        xl="2"
      >
        <v-skeleton-loader
          v-if="isLoading"
          :loading="isLoading"
          class="mx-auto"
          max-width="300"
          type="image"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-if="!isLoading && total > 16">
      <v-col
        :cols="$vuetify.breakpoint.xsOnly && paginate.page > 1 ? 12 : 6"
        class="d-flex align-center"
        >{{ pagination }}</v-col
      >
      <v-col
        :cols="$vuetify.breakpoint.xsOnly && paginate.page > 1 ? 12 : 6"
        class="pagination-btns"
      >
        <v-btn
          v-if="paginate.page > 1"
          @click="goToPreviousPage()"
          depressed
          class="previous-btn"
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.9991 19L9.83911 14C9.56672 13.7429 9.34974 13.433 9.20142 13.0891C9.0531 12.7452 8.97656 12.3745 8.97656 12C8.97656 11.6255 9.0531 11.2548 9.20142 10.9109C9.34974 10.567 9.56672 10.2571 9.83911 10L14.9991 5"
              stroke="#000000"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span class="px-1">Previous</span>
        </v-btn>
        <v-btn
          v-if="paginate.page != lastPage"
          @click="goToNextPage()"
          depressed
          class="next-button"
        >
          <span class="">Next</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path
              d="M4.28257 9.74991C4.17952 9.75196 4.07843 9.71954 3.99327 9.65714C3.90812 9.59473 3.84312 9.50543 3.80727 9.40158C3.77142 9.29774 3.76649 9.18448 3.79317 9.07749C3.81984 8.9705 3.8768 8.87507 3.95616 8.80439L6.99332 6.01018L3.95428 3.21596C3.89876 3.17246 3.85223 3.11707 3.81764 3.05328C3.78304 2.98949 3.76112 2.91868 3.75326 2.84533C3.74541 2.77198 3.75178 2.69767 3.77199 2.6271C3.7922 2.55653 3.82581 2.49123 3.87068 2.43532C3.91555 2.3794 3.97073 2.33409 4.03271 2.30225C4.0947 2.2704 4.16216 2.25271 4.23083 2.25029C4.2995 2.24787 4.36789 2.26077 4.43169 2.28818C4.49548 2.31559 4.5533 2.35692 4.60149 2.40955L8.07575 5.60092C8.13039 5.65108 8.17426 5.71337 8.20434 5.7835C8.23442 5.85363 8.25 5.92993 8.25 6.00715C8.25 6.08438 8.23442 6.16068 8.20434 6.23081C8.17426 6.30094 8.13039 6.36323 8.07575 6.41338L4.60148 9.61483C4.51344 9.69907 4.40042 9.74694 4.28257 9.74991Z"
              fill="white"
            />
          </svg>
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      max-width="700px"
      max-height="60vh"
      overlay-opacity="0.8"
    >
      <v-carousel hide-delimiters reverse v-model="slider">
        <v-carousel-item
          v-for="(media, i) in allMedia"
          :src="media.url"
          :key="i"
          active-class="image-style"
          class="modal-img"
        >
        </v-carousel-item>
      </v-carousel>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/services/Api";
import { showErrorMessage } from "@/utils/showError";
import EventBus from "../../js/eventBus";
import { API_URL } from "@/utils/env.js";
export default {
  name: "TransactionMedia",
  data() {
    return {
      dialog: false,
      slider: 0,
      selectedImage: null,
      allMedia: [],
      mediaFilterValue: "liveness_photo_id_match",
      isLoading: false,
      totalRecord: null,
      paginate: {
        page: 1,
        itemsPerPage: 16,
      },
      total: null,
      lastPage: null,
      baseUrl: API_URL,
      mediaFilter: [
        {
          value: "liveness",
          label: "Liveness",
        },
        {
          value: "liveness_photo_id_match",
          label: "ID Scan",
        },
      ],
    };
  },
  computed: {
    /**
     * Generates a pagination string indicating the range of items currently being viewed.
     *
     * @method pagination
     * @returns {String} A string indicating the range of items being viewed.
     */
    pagination() {
      return (
        "Viewing " +
        (this.paginate.page * this.paginate.itemsPerPage -
          this.paginate.itemsPerPage +
          1) +
        " to " +
        (this.paginate.page * this.paginate.itemsPerPage <= this.total
          ? this.paginate.page * this.paginate.itemsPerPage
          : this.total) +
        " of " +
        this.total
      );
    },
  },
  watch: {
    paginate: {
      handler() {
        this.getAllMedia();
      },
      deep: true,
    },
  },

  methods: {
    /**
     * Opens the image dialog with the specified media and index. Sets the slider index,
     * selects the image URL, and opens the dialog.
     *
     * @method openImageDialog
     * @param {Object} media - The media object containing the image URL.
     * @param {Number} i - The index of the image in the slider.
     */
    openImageDialog(media, i) {
      this.slider = i;
      this.selectedImage = media.url;
      this.dialog = true;
    },

    /**
     * Retrieves all media data. Sends a GET request to fetch the media data from the server
     * based on pagination, media filter value, and client secret. Updates the component state
     * with the fetched data upon success, including allMedia, total, lastPage, and totalRecord.
     * Emits a "total-enrolled-faces" event with the total number of enrolled faces.
     *
     * @method getAllMedia
     */
    getAllMedia() {
      this.isLoading = true;
      ApiService.GetRequest("/merchant/client")
        .then((res) => {
          let data = {
            pagination: this.paginate.itemsPerPage,
            page: this.paginate.page,
            is_pagination: true,
            transaction_type: this.mediaFilterValue,
          };
          ApiService.PostRequest(
            "/transaction/enrolled-faces",
            data,
            "iframe",
            res.data.result.data.client_secret
          )
            .then((response) => {
              this.isLoading = false;
              this.allMedia = response.data.result.data.data;
              this.total = response.data.result.data.total;
              this.lastPage = response.data.result.data.last_page;
              this.totalRecord = response.data.result.data.total;
              this.$emit(
                "total-enrolled-faces",
                response.data.result.data.total
              );
            })
            .catch((error) => {
              this.isLoading = false;
              showErrorMessage(error);
            });
        })
        .catch((err) => {
          this.isLoading = false;
          showErrorMessage(err);
        });
    },

    /**
     * Navigates to the next page of media data.
     *
     * @method goToNextPage
     */
    goToNextPage() {
      this.paginate.page++;
    },

    /**
     * Navigates to the previous page of media data.
     *
     * @method goToPreviousPage
     */
    goToPreviousPage() {
      this.paginate.page--;
    },
  },
  mounted() {
    this.getAllMedia();
  },
  created() {
    EventBus.$on("getEnrollFaces", this.getAllMedia);
  },
  beforeDestroy() {
    EventBus.$off("getEnrollFaces");
  },
};
</script>

<style scoped>
.status-declined {
  font-size: 12px !important;
  font-weight: 500;
  color: #ff784b !important;
  background-color: #faf2f0 !important;
}

.status-accepted,
.response-true {
  font-size: 12px !important;
  font-weight: 500;
  color: #4fb0b9 !important;
  background-color: #edf4f5 !important;
}

.v-select {
  max-width: 100%;
  margin-bottom: 10px;
  margin-left: 0px;
}
.image-card-container {
  padding: 8px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 1.4px 13px 0px rgba(0, 0, 0, 0.03),
    0px 7px 80px 0px rgba(0, 0, 0, 0.05);
}
.pagination-btns {
  display: flex;
  justify-content: end;
  align-items: center;
  column-gap: 5px;
}
.pagination-btns .previous-btn {
  height: 32px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  background: #fff;
  color: #2a2c31;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.pagination-btns .next-button {
  height: 32px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #213cc8 !important;
  color: #fff;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.modal-img .v-image__image--cover {
  background-size: contain;
}
.modal-img .v-carousel__item {
  background: #e9e9e9;
}
.images-container {
  max-height: 475px;
  overflow-y: auto;
}
</style>
